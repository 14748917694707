<template>
  <div class="upload">
    <div class="upload-header">
      <div class="info">
        <div class="labels">
          <TextLabel customClass="title" :label="title" />
          <TextLabel customClass="subtitle" :label="`(${subtitle})`" />
        </div>
        <TextLabel
          customClass="filename"
          v-if="currentFilename"
          :label="$t('returns.upload.fileAdded', {currentFilename: currentFilename})"
        />
      </div>
      <input
        type="file"
        :id="`file-${pov}`"
        ref="file"
        accept=".jpg, .jpeg, .png, .webp"
        @change="compress"
      />
    </div>
    <div class="upload-button">
      <Button
        v-if="!getReturn.submitted"
        :disabled="disabled"
        :label="!currentFilename ? $t('returns.upload.buttonLabel') : ''"
        :fill="false"
        type="tertiary"
        :border="true"
        :withIcon="currentFilename.length > 0"
        icon="check"
        uppercase
        rounded
        @onClick="submitFile"
      ></Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { Button, TextLabel } from "@sc/ds-ui-component-library";

// MAX 10MB
const MAX_ALLOWED = 10485760;

export default {
  name: "UploadFile",
  components: {
    Button,
    TextLabel,
  },
  data() {
    return {
      browseFile: false,
      file: "",
      filename: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    subtitle: {
      type: String,
      default: "Subtitle",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pov: {
      type: String,
    },
  },
  methods: {
    async handleFileUpload(rawFile) {
      this.filename = rawFile.name;
      this.$emit("onUpload", { content: rawFile, name: this.filename });

    },
    async compress(e) {
      this.$store.commit("SpinnerModule/setLoading", true, { root: true });
      const file = e.target.files[0];
      const blob = file.slice(0, 4);
      const buffer = await blob.arrayBuffer();
      const uint = new Uint8Array(buffer);
      let bytes = [];
      uint.forEach((byte) => {
        bytes.push(byte.toString(16));
      });
      const hex = bytes.join("").toUpperCase();
      const mimeType = this.checkMimeType(hex);

      if (
        !(
          (mimeType === "image/jpeg" ||
            mimeType === "image/png") &&
          (file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png")
        )
      ) {
        this.$store.commit("SpinnerModule/setLoading", false, { root: true });
        this.$refs.file.value = null;
        this.$toast.open({
          message: this.$t("toast.uploadFile.invalidFileTypeError"),
          dismissible: true,
          type: "error",
        });
        return;
      }

      if (file.size < MAX_ALLOWED) {

        this.handleFileUpload(file)

      } else {
        this.$store.commit("SpinnerModule/setLoading", false, { root: true });
        this.$refs.file.value = null;
        this.$toast.open({
          message: this.$t("toast.uploadFile.maximumFileSizeError"),
          dismissible: true,
          type: "error",
        });
        return;
      }
    },
    submitFile() {
      document.getElementById(`file-${this.pov}`).click();
    },
    downloadFile() {
      this.$emit("onDownload", { content: this.file, name: this.filename });
    },
    checkMimeType(signature) {
      switch (signature) {
        case "89504E47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "25504446":
          return "application/pdf";
        case "FFD8FFE8":
        case "FFD8FFE3":
        case "FFD8FFE2":
        case "FFD8FFE1":
        case "FFD8FFE0":
        case "FFD8FFDB":
        case "FFD8FFEE":
          return "image/jpeg";
        case "52494646":
          return "image/webp";
        case "504B0304":
          return "application/zip";
        default:
          return "Unknown filetype";
      }
    },
  },
  computed: {
    ...mapGetters({
      getReturn: "ReturnModule/getReturn",
      getReturnImages: "ReturnModule/getReturnImages",
      getReturnImagesPhaseTwoV3: "ReturnModule/getReturnImagesPhaseTwoV3",
    }),
    currentFilename() {
      return (
        this.filename || this.getReturnImagesPhaseTwoV3?.find((image) => image.name === this.pov)?.file.name
        || this.getReturnImages?.find((image) => image.name === this.pov)?.file
          ?.name ||
        ""
      );
    },
  },
};
</script>

<style lang="css" scoped src="./UploadFile.css"></style>
