<template>
  <div class="car-features">
    <div class="car-features-general">
      <div class="car-features--title">
        <TextLabel :label="$t('returns.sections.carFeatures.title')" />
      </div>
      <div class="car-features--content">
        <div class="car-features--general">
          <div class="car-features--general-input">
            <Dropdown
              selectedOnHeader
              :items="externalList"
              :key="'externalColor'"
              :name="$t('returns.sections.carFeatures.inputs.externalColor')"
              :disabled="getReturn.submitted"
             @onSelectedItems="(e) => handleEmit('externalColor', e)"
            />
            <Indicator
              key="months-indicator-01"
              v-show="!validationFlags.externalColor"
              :type="`error`"
              :label="
                $t('returns.sections.carFeatures.indicators.externalColor')
              "
              :icon="`exclamation-mark`"
            />
          </div>
          <div class="car-features--general-input">
            <Dropdown
              selectedOnHeader
              :items="interiorList"
              :key="'cabinTrimInlay'"
              :name="$t('returns.sections.carFeatures.inputs.internalColor')"
              :disabled="getReturn.submitted"
              @onSelectedItems="(e) => handleEmit('interiorColor', e)"
            />
            <Indicator
              key="months-indicator-01"
              v-show="!validationFlags.interiorColor"
              :type="`error`"
              :label="
                $t('returns.sections.carFeatures.indicators.interiorWarning')
              "
              :icon="`exclamation-mark`"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="car-features-imported">
      <div class="car-features--title">
        <TextLabel :label="$t('returns.sections.carFeatures.isImported')" />
      </div>
      <div class="car-features--equipment-list">
        <RadioGroup
          :disabled="getReturn.submitted"
          :options="provenance.radioGroupList"
          @onInput="(e) => handleEmit('provenance', e)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  RadioGroup,
  TextLabel,
  Dropdown,
  Indicator,
} from "@sc/ds-ui-component-library";

export default {
  name: "CarFeatures",
  components: {
    TextLabel,
    Dropdown,
    RadioGroup,
    Indicator,
  },
  data() {
    return {
      validationFlags: {
        interiorColor: true,
        externalColor: true,
        provenance: true,
      },
    };
  },
  props: {
    hasSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getFirst(arr) {
      const selected = arr.filter((it) => it.selected);
      if (selected.length) return selected[0];
      else return false;
    },
    handleEmit(input, e) {
      switch (input) {
        case "externalColor":
          this.handleExternalColor(e);
          break;
        case "interiorColor":
          this.handleInteriorColor(e);
          break;
        case "provenance":
          this.handleProvenance(e);
          break;
        default:
      }
    },
    handleExternalColor(e) {
      this.validationFlags.externalColor = this.getFirst(e).selected;
      this.$store.commit(
        "ReturnModule/setExternalColor",
        this.getFirst(e) ? this.getFirst(e) : ""
      );
    },
    handleInteriorColor(e) {
      this.validationFlags.interiorColor = this.getFirst(e).selected;
      this.$store.commit(
        "ReturnModule/setInteriorColor",
        this.getFirst(e) ? this.getFirst(e) : ""
      );
    },
    handleProvenance(e) {
      const provenance = e.find((e) => e.checked).radioValue;
      this.validationFlags.provenance = e.some((e) => e.checked);

      this.$store.commit("ReturnModule/setProvenance", provenance);
    },
  },
  computed: {
    ...mapGetters({
      getReturn: "ReturnModule/getReturn",
      getReturnKilometers: "ReturnModule/getReturnKilometers",
      externalList: "ReturnModule/getExternalColorList",
      interiorList: "ReturnModule/getInteriorList",
      provenance: "ReturnModule/getProvenance",
    }),
  },
  created() {
    if (this.hasSubmitted) {
      this.handleEmit();
    }
  },
};
</script>

<style lang="scss" scoped>
.car-features {
  display: flex;
  flex-direction: column;
}

::v-deep .selectNative {
  width: 100%;
}

.car-features-general,
.car-features-equipment,
.car-features-imported {
  width: 100%;
  display: flex;
}

.car-features--content {
  width: 100%;
  :deep(.dropdown-header) {
    &.box {
      .dropdown-header-inner {
        --text-label-color: var(--cv-black);
        --text-label-weight: 600;
      }
    }

    &.selected {
      .dropdown-header-inner {
        --text-label-color: var(--cv-secondary);
        --text-label-weight: 600;
      }
    }
  }
}

.car-features--equipment-left,
.car-features--equipment-right,
.car-features--equipment-list {
  width: 100%;
}

.car-features--equipment-list {
  align-content: center;
  display: flex;
}

.car-features--title {
  width: 33%;
  padding-right: 40px;
  text-align: left;
  --text-label-font-size: 16px;
  --text-label-line-height: 16px;
  --text-label-weight: bold;
  --text-label-color: var(--cv-black);
}

.car-features--general-title .label,
.car-features--equipment-title .label {
  color: var(--color-1);
}

.car-features--title {
  margin-bottom: 1.75rem /* 28px */;
}
.car-features--equipment-title {
  margin-top: 1.75rem /* 28px */;
  margin-bottom: 0.25rem /* 4px */;
}

.car-features--general-title {
  margin-bottom: 1rem /* 16px */;
}

.car-features--general {
  & > * {
    margin: 9px 0;
  }

  & > *:first-child {
    margin: 0 0 9px 0;
  }
}

::v-deep .input--wrapper {
  width: 100%;
}

::v-deep .selectCustom {
  width: 100%;
}

::v-deep .selectWrapper {
  width: 100%;
}

@media (min-width: 1024px) {
  :deep(.dropdown--wrapper) {
    width: 441px;
  }
}

@media (max-width: 1024px) {
  .car-features-general,
  .car-features-imported {
    flex-direction: column;
  }

  .car-features--title {
    margin-bottom: 24px;
  }

  .car-features--general {
    & > * {
      margin: 9px 0;
    }

    & > *:first-child {
      margin: 0 0 9px 0;
    }

    .car-features--general-input {
      margin-top: 0px;
    }
  }
}
</style>
