<template>
  <div
    :class="['checkbox--checkboxlabel', customClass, classes]"
    :styles="customStyles"
  >
    <div class="checkbox">
      <Checkbox
        :type="currentCheckboxType"
        :checked.sync="currentChecked"
        :fill="currentFill"
        :fillOnChecked="fillOnChecked"
        :disabled="disabled"
        @onChange="onChangeHandler"
      ></Checkbox>
    </div>
    <div class="checkbox--content">
      <div class="checkbox--content-title">
        <Label
          :bold="bold"
          :color="currentColorTitle"
          :size="sizeTitle"
          :uppercase="uppercase"
          >{{ title }}</Label
        >
      </div>
      <div class="checkbox--content-subtitle">
        <Label size="x-small" :color="colorSubtitle" v-html="subtitle">{{
          subtitle
        }}</Label>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Label } from "../../atoms";
export default {
  components: {
    Checkbox,
    Label,
  },
  data() {
    return {
      colorSubtitle: "tertiary",
      sizeTitle: "small",
    };
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    // The checkbox type refers to the type of the checkbox component when checked.
    type: {
      type: String,
      default: "primary",
      validator: (type) => {
        return ["primary", "secondary"].includes(type);
      },
    },
    // The checkbox type refers to the type of the checkbox component.
    checkboxType: {
      type: String,
      default: "primary",
      validator: (type) => {
        return ["primary", "secondary"].includes(type);
      },
    },
    checked: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    fillOnChecked: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: String,
      default: "bold",
      validator: (value) => {
        return [
          "thin",
          "extra-light",
          "normal",
          "semibold",
          "bold",
          "extra-bold",
        ].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChangeHandler(checked) {
      this.currentChecked = checked;
      this.$emit("onChange", checked);
    },
  },
  computed: {
    classes() {
      return {};
    },
    customStyles() {
      return {
        ...this.styles,
      };
    },
    currentChecked: {
      get() {
        return this.checked;
      },
      set(checked) {
        this.$emit("update:checked", checked);
      },
    },
    currentColorTitle() {
      if (this.type === "primary") {
        return this.currentChecked ? "secondary" : "primary";
      } else {
        return "primary";
      }
    },
    currentFill() {
      if (this.type === "secondary") {
        return this.currentFill ? true : false;
      }
      return this.fill;
    },
    currentCheckboxType() {
      if (this.checkboxType === "primary") {
        return this.currentChecked ? "secondary" : "primary";
      } else {
        return "secondary";
      }
    },
  },
};
</script>
<style lang="css" scoped src="./CheckboxLabel.css"></style>
