<template>
  <Section id="section-carousel">
    <div class="carousel--container">
      <Heading
        class="header"
        :label="$t('homepage.trustpilot.title')"
        tag="h2"
      />
      <WidgetMini
        :businessunitId="businessunitId"
        :href="trustpilotHref"
        :dataLocale="locale"
      />
      <div class="carousel">
        <WidgetCarousel
          :businessunitId="businessunitId"
          :href="trustpilotHref"
          :dataLocale="locale"
        />
      </div>
    </div>
  </Section>
</template>

<script>
import {
  Section,
  WidgetCarousel,
  WidgetMini,
  Heading
} from "@sc/ds-ui-component-library";

export default {
  name: "TrustPilotCarousel",
  components: {
    Section,
    WidgetCarousel,
    WidgetMini,
    Heading,
  },
  props: {
    trustpilotHref: {
      type: String,
    },
    businessunitId: {
      type: String,
    },
    locale: {
      type: String,
    },
  },
  methods: {
    navigateTo() {
      window.open(this.trustpilotHref, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
#section-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  --heading-color: var(--color-1);
  text-align: center;
 --section-bg-color:#ffffff;
  --section-padding: 96px 0px 46px 0px;

  .trustpilot-widget {
    width: 100%;
  }
  .header {
    --heading-large-font-size: 28px;
    --heading-large-line-height: 28px;
    --heading-color: var(--cv-secondary);
  }

  .carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    width: 100%;

    &:before {
      content: "";
      height: 1px;
      width: 90%;
      background-color: var(--color-4);
    }
  }
}

.carousel--container {
  display: flex;
  gap: 64px;
  flex-direction: column;
  max-width: 1380px;
  width: 100%;
}

@media (max-width: 1440px) {
  #section-carousel {
    --section-padding: 96px 90px 46px 90px;
  }
}

@media (max-width: 896px) {
  #section-carousel {
    --section-padding: 56px 30px 16px 30px;
  }
  ::v-deep .heading {
    --heading-large-font-size: 24px;
    --heading-large-line-height: 24px;
    --heading-weight: 800;
  }
}

@media (max-width: 768px) {
  .carousel--container {
    gap: 50px;
  }
}

@media (max-width: 475px) {
  #section-carousel {
    --section-padding: 56px 10px 16px 10px;
  }
}
</style>
