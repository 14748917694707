
const UTM_PREFIX = "utm_";

const getUtmParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const params = Object.fromEntries(urlSearchParams.entries());

  const utmParams = Object.keys(params)
    .filter((key) => key.includes(UTM_PREFIX))
    .reduce((res, key) => ((res[key] = params[key]), res), {});

  return utmParams;
};

const dealerNameFormat = (p) => {
  const stringsToRemove = [
    "carplus",
    "clicayvendecoche",
    "clicaevende"
  ];
  
  let filteredString = p
                        .replace(/[()\s]/g, '')
                        .toLowerCase();

  stringsToRemove.forEach((stringToRemove) => {
    filteredString = filteredString.replace(stringToRemove, "");
  });
  filteredString = filteredString.charAt(0).toUpperCase() + filteredString.slice(1);
  return filteredString;
};
export { getUtmParams,dealerNameFormat};
