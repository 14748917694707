<template>
  <div class="no-results">
    <div class="no-results--title">
      <Label size="medium" color="primary">{{ title }}</Label>
    </div>
    <div v-if="description" class="no-results--description">
      <Label size="small" color="quinary">{{ description }}</Label>
    </div>
    <div v-if="labelButton" class="no-results--button">
      <Button
        customClass="custom-btn"
        :label="labelButton"
        @onClick="onClickHandler"
      />
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { Button } from "@sc/ds-ui-component-library";
export default {
  name: "NoResults",
  components: {
    Label,
    Button,
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    labelButton: {
      type: String,
    },
  },
  methods: {
    onClickHandler() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.no-results {
  text-align: center;
}

.no-results--title {
  margin-bottom: 0.5rem;
}

.no-results--title .label {
  font-weight: 500;
}

.no-results--description {
  margin-bottom: 1.5rem;
}

.no-results--description .label {
  font-family: Mont;
}

.no-results--button .button {
  width: 241px;
  height: 56px;
  margin: auto;
  font-family: Mont;
}

.custom-btn {
  --button-primary-bg-color: var(--theme-color-quaternary);
  --button-text-color: var(--theme-color-quinary);
  --button-font-size: 16px;
  --button-line-height: 16px;
  --button-font-weight: 700;
  --button-padding: 10px 50px;
  &:hover,
  &:active {
    --button-primary-hover: var(--theme-color-quinary);
    --button-text-color: var(--color-7);
    --button-primary-border: 1.50459px solid var(--theme-color-quinary);
  }
}

::v-deep .label {
  font-family: Mont;
}
@media (max-width: 1024px) {
  .no-results--button {
    .button {
      width: 100%;
      justify-content: center;
      margin: 0;
    }
  }
}
</style>
