<template>
  <div id="app">
    <BaseAuth v-if="prodBaseAuth" @onValidate="clearBaseAuth" />
    <div class="navbar-footer--container">
      <Navbar id="navbar-page" ref="navbar">
        <template v-slot:left>
          <router-link to="/">
            <div class="logo-container">
              <img :src="appLogo" alt="logo carplus" />
            </div>
          </router-link>
        </template>
        <template v-slot:center>
          <LinkList
            :class="getSelectedPage"
            @onClick="closeMobileMenu"
            :list="list"
            bold="normal"
            display="horizontal"
            size="lg"
          />
          <Button
            :label="$t('navbar.anchor.3.title')"
            customClass="contact-button"
            type="quinary"
            @onClick="openContactPopup"
          />
        </template>
        <template v-slot:right-2>
          <div class="reserved-area">
            <div class="caetano-go-widget" data-idgo-widget />
            <SessionLink
              v-show="displayReservedArea"
              @onRedirect="openPersonalPanel"
              :numberOfEvals="retakeCount"
            />
          </div>
        </template>
      </Navbar>
    </div>
    <transition name="fade">
      <router-view :key="$route.path" />
    </transition>
    <div class="navbar-footer--container">
      <Footer @openContactPopup="openContactPopup" />
    </div>

    <Spinner :loading="isLoading" />
    <Alert
      class="alert-notification"
      v-if="getAlert.display"
      :type="getAlert.type"
      :type-label="$t('toast.label') !== 'toast.label' ? $t('toast.label') : {}"
      :message="getAlert.message"
      :global="getAlert.global"
      @closeAlert="clearAlert"
    />
    <PersonalPanel
      v-if="isOpenPersonal"
      @onClosePersonalPanel="onClosedPanel"
    />
    <ReturnPanel v-if="isOpenReturnPanel" @returnPanelClosed="onClosedPanel" />
    <ContactPopup
      v-if="isContactPopupOpen"
      @onClose="onCloseContactPopup"
      @onSubmit="submitContactLead"
    />
    <ConcludeSellPanel
      v-if="isOpenConcludeSell"
      @onClosePanel="onClosedPanel(false)"
    />
    <RetakePanel v-if="isOpenRetakePanel" @retakePanelClosed="onClosedPanel" />
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import {
  Navbar,
  Footer,
  Spinner,
  BaseAuth,
  SessionLink,
} from "./components";
import { LinkList, Alert, Button } from "@sc/ds-ui-component-library";
import { ContactPopup } from "./components/organisms";
import { leadContact } from "./api/lead";
import { mapGetters, mapMutations } from "vuex";
import store from "./store";
import { preLogin, getGuestToken } from "./api/authentication";

export default {
  components: {
    BaseAuth,
    Navbar,
    Footer,
    Spinner,
    LinkList,
    Alert,
    Button,
    ContactPopup,
    SessionLink,
    PersonalPanel: () =>
      import("./components/panels/PersonalPanel/PersonalPanel.vue"),
    ReturnPanel: () =>
      import("./components/panels/ReturnPanel/ReturnPanel.vue"),
    ConcludeSellPanel: () =>
      import("./components/panels/ConcludeSellPanel/ConcludeSellPanel.vue"),
    RetakePanel: () =>
      import("./components/panels/RetakePanel/RetakePanel.vue"),
  },
  metaInfo() {
    return {
      title: this.$t("meta.homepage.title"),
      meta: parseInt(process.env.VUE_APP_ENABLE_GTM)
        ? [
          {
            name: "google-site-verification",
            content: process.env.VUE_APP_GOOGLE_SITE_VERIFICATION_CONTENT,
          },
          {
            name: "facebook-domain-verification",
            content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION_CONTENT,
          }
        ]
        : 
        [
          {
          name: "facebook-domain-verification",
          content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION_CONTENT,
          }
        ],
    script: process.env.VUE_APP_COUNTRY === 'PT' ? [
      {
        src: "https://plausible.io/js/script.js",
        defer: true,
        "data-domain": process.env.VUE_APP_PLAUSIBLE_DOMAIN,
      }
    ]: []
    };
  },
  data() {
    return {
      prodBaseAuth: process.env.VUE_APP_PROD_BASE_AUTH,
      priceDropType: "PRICE-DROP",
      showReservedArea: false,
      personalAction: "/pessoal",
      loginAction: "?action=login",
      logoutAction: "?action=logout",
      preloginRes: {},
      bottomQlKey: 0,
      renderComponent: true,
      isRegisterFormOpen: false,
      user: {},
      userObj: {},
      passwordSent: false,
      verificationAccountOpened: false,
      verifiedAccount: false,
      linkListClass: "none-selected",
      isContactPopupOpen: false,
      previousURL: "",
      reload: false,
    };
  }, 
  async created() {
    if (!process.env.VUE_APP_PROD) {
        var meta = document.createElement('meta');
        meta.name = "robots";
        meta.content = "noindex, nofollow";
        document.head.appendChild(meta);
    }


    const loggedInToken = localStorage.getItem("token");
    const isGuest = JSON.parse(localStorage.getItem("isGuest")) || true;

    if (!loggedInToken) {
      await store.dispatch("fetchGuestToken");
    } else {
      store.commit("setToken", loggedInToken);
      store.commit("setIsGuest", isGuest);
    }

    this.initWidget();


  },
  async mounted() {
    if (!localStorage.getItem("dssessionId"))
      localStorage.setItem("dssessionId", uuid());

    if (!this.isGuest) await this.$store.dispatch("storeUserInfo");

    // handle no scroll when panels are open
    let caetanoGoWidget = document.querySelector(".caetano-go-widget");
    this.isAnyPanelOpen
      ? (document.body.classList.add("no-scroll"),
        (caetanoGoWidget.style.zIndex = "5"))
      : document.body.classList.remove("no-scroll");

    // Temporary condition to support base auth
    if (this.prodBaseAuth) document.body.classList.add("no-scroll");

    if(this.$route.hash == this.$t("navbar.anchor.3.anchor")){
      this.isContactPopupOpen = true
    }

  },
  watch: {
    $route(to, from) {
      const newUrl = to.fullPath;
      const fromUrl = from.fullPath;
      this.previousURL = fromUrl;

      this.$store.state.isOpenNavbar = false;

      if (newUrl.includes(this.loginAction)) {
        const newUrl = sessionStorage.getItem("url");
        this.$router.push(newUrl);
      } else if (newUrl.includes(this.logoutAction)) {
        const newUrl = sessionStorage.getItem("url");
        this.$router.push(newUrl.split(this.personalAction)[0]);
      } else {
        sessionStorage.setItem("url", newUrl.replace(this.logoutAction, ""));
        sessionStorage.setItem(
          "previousUrl",
          fromUrl.replace(this.logoutAction, "")
        );
      }
    },
  },
  async updated() {
    if (this.reload) {
      await this.initWidget();
    }
  },
  methods: {
    clearBaseAuth(e) {
      if (e) {
        this.prodBaseAuth = false;
        document.body.classList.remove("no-scroll");
      }
    },
    ...mapMutations({
      togglePersonalPanel: "PanelModule/togglePersonalPanel",
      clearAlert: "AlertModule/clearAlert",
      toggleNavbar: "toggleNavbar",
    }),
    closeMobileMenu() {
      if (this.isOpenNavbar) this.toggleNavbar(document);
    },
    async onLogoutHandler() {
      await this.$store.dispatch("storeLogout");
    },
    openContactPopup() {
      this.isContactPopupOpen = true;
      if (this.isOpenNavbar) this.toggleNavbar(document);
      this.$router.push({ hash: this.$t("navbar.anchor.3.anchor") })
      document.body.classList.add("no-scroll");
    },
    async openPersonalPanel() {
      await this.$store.dispatch("RetakeModule/storeGetAllRetakes");
      this.togglePersonalPanel();

      this.isOpenPersonal
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
    },
    onCloseContactPopup() {
      this.isContactPopupOpen = false;
      this.$router.push({ hash: "" })
      document.body.classList.remove("no-scroll");
    },
    onClosedPanel(removeNoScroll = true) {
      if (removeNoScroll) document.body.classList.remove("no-scroll");
    },
    async submitContactLead(payload) {
      try {
        payload.vin = "";
        payload.dropPriceNotificationDetails = {};
        payload.finance = {};
        payload.tradeInId = "";
        payload.day = "";
        payload.time = "";

        this.$store.commit("spinnerModule/setLoading", true, { root: true });
        let response = await leadContact(payload);

        if (response && response.status === 200) {
          //Google Tag Manager success contact event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "askContact",
          });

          this.$toast.open({
            message: this.$t("toast.contact.success"),
            dismissible: true,
            type: "info",
          });
        }
      } catch (error) {
        this.$store.commit("AlertModule/setAlert", {
          message: this.$t("toast.contact.error"),
          type: "error",
          display: true,
          global: true,
        });
      }
      this.$store.commit("spinnerModule/setLoading", false, { root: true });
      this.onCloseContactPopup();
    },
    async initWidget() {
      let response = await preLogin();

      if (response?.response?.status === 401 || this.reload) {
        await getGuestToken();
        response = await preLogin();
        this.reload = !this.reload;
      }

      this.preloginRes =
        response &&
        response.data &&
        response.data.data &&
        response.data.data.url
          ? response.data.data.url
          : "";

      if (this.preloginRes) {
        this.$loadScript(this.preloginRes).then(() => {
          this.$nextTick(() => {
            this.hasReservedArea();
          });

          setTimeout(async () => {
            this.reloadOnBadPrelogin();

            if (
              document.querySelectorAll('[data-idgo-login=""]').length &&
              localStorage.getItem("isGuest") === "false"
            ) {
              await this.onLogoutHandler();
            } else if (
              document.querySelector("[data-idgo-dropdown-trigger]") &&
              (localStorage.getItem("isGuest") === "false" ||
                localStorage.getItem("isGuest") === "true")
            ) {
              await store.dispatch("revalidateLogin", store.getters.getUserObj);
            }
          }, 2000);
        });
      }
    },
    reloadOnBadPrelogin() {
      if (
        (document.querySelector("[data-idgo-dropdown-trigger]") &&
          this.previousURL.slice(1) === this.logoutAction) ||
        (document.querySelectorAll('[data-idgo-login=""]').length &&
          this.previousURL.slice(1) === this.loginAction)
      ) {
        document.location.reload();
      }
    },
    hasReservedArea() {
      if (
        document.querySelectorAll('[data-idgo-login=""]').length &&
        localStorage.getItem("isGuest") === "false"
      ) {
        this.showReservedArea = false;
      } else if (
        document.querySelector("[data-idgo-dropdown-trigger]") &&
        (localStorage.getItem("isGuest") === "false" ||
          localStorage.getItem("isGuest") === "true")
      ) {
        this.showReservedArea = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "SpinnerModule/isLoading",
      getAlert: "AlertModule/getAlert",
      isAnyPanelOpen: "PanelModule/isAnyPanelOpen",
      isOpenPersonal: "PanelModule/isOpenPersonal",
      isOpenRetakePanel: "PanelModule/isOpenRetake",
      isOpenReturnPanel: "PanelModule/isOpenReturn",
      isOpenConcludeSell: "PanelModule/isOpenConcludeSell",
      retakeCount: "RetakeModule/getRetakesCount",
      isOpenNavbar: "getIsOpenNavbar",
      isUserGuest: "isGuest",
    }),
    appLogo() {
      return (
        this.$t("resources.images.appLogo") || require("./assets/logo.svg")
      );
    },
    list() {
      return [
        {
          label: this.$t("navbar.anchor.1.title"),
          url: this.$t("navbar.anchor.1.path"),
        },
        {
          label: this.$t("navbar.anchor.2.title"),
          url: this.$t("navbar.anchor.2.path"),
        },
      ];
    },
    getSelectedPage() {
      return this.linkListClass;
    },
    displayReservedArea() {
      return this.showReservedArea;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.none-selected {
  ::v-deep a .link--bold-normal {
    font-weight: 600;
  }
}

.first-selected {
  :nth-child(1) ::v-deep a .link--bold-normal {
    font-weight: bold;
  }
  ::v-deep a .link--bold-normal {
    font-weight: 600;
  }
}
.second-selected {
  :nth-child(2) ::v-deep a .link--bold-normal {
    font-weight: bold;
  }
  ::v-deep a .link--bold-normal {
    font-weight: 600;
  }
}
.third-selected {
  :nth-child(3) ::v-deep a .link--bold-normal {
    font-weight: bold;
  }
  ::v-deep a .link--bold-normal {
    font-weight: 600;
  }
}

.contact-button {
  margin-left: 20px;
  font-family: "Mont";
  --button-font-size: 16px;
  --button-line-height: 16px;
  --button-font-weight: 500;
  &:hover,
  &:active {
    --button-font-weight: 700;
  }
}

.account-image {
  width: 40px;
  height: 40px;
}

.session--link {
  color: #333;
  @apply ml-2.5;
}

.icon--wrapper {
  display: grid;
  place-items: center;
  height: 44px;
  width: 44px;
  margin-right: 20px;
}

.usericon--wrapper {
  display: grid;
  place-items: center;
  height: 40px;
  width: 40px;
}

.usericon--wrapper > div {
  position: relative;
}

.icon--wrapper > div {
  position: relative;
}

.logo-container {
  display: grid;
  place-items: center;
  width: 210px;
  height: 52px;
}

.reserved-area {
  display: flex;
  min-width: 244px;
}

::v-deep .idgo-widget .idgo-wrapper .options .user > .dropdown {
  z-index: 10;
}

.caetano-go-widget {
  z-index: 10;

  ::v-deep .idgo-widget img {
    filter: invert(100%) sepia(12%) saturate(2%) hue-rotate(136deg)
      brightness(112%) contrast(101%);
  }
  ::v-deep .user span {
    color: #ffffff !important;
  }
}

.alert-notification {
  position: fixed;
  z-index: 3000;
  top: 16px;
  right: 16px;
  left: 16px;
  margin: auto;
  gap: 10px;

  ::v-deep {
    .left {
      gap: 10px;
    }
    .icon {
      cursor: pointer;
    }
  }
}

@media (min-width: 1024px) {
  .alert-notification {
    position: fixed;
    z-index: 3000;
    top: 16px;
    right: 16px;
    left: auto;
    //width: 687px;
    margin: 0px;
    gap: 10px;

    ::v-deep {
      .left {
        gap: 10px;
      }
      .icon {
        cursor: pointer;
      }
    }
  }
}

.navbar {
  background-color: #00064b;
  height: 110px;
}

.navbar-footer--container {
  background-color: #00064b;
  display: flex;
  justify-content: center;
}

::v-deep .link-list .item a {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  text-decoration: none;
}

@media (max-width: 1280px) {
  .logo-container {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .logo-container {
    width: 150px;
  }
}
</style>
