const LOCALE = "es";

function getPriceFormatted(
  price,
  options = {},
  minFractionDigit = 2,
  maxFractionDigit = 2
) {
  if (typeof price === "string") price = parseFloat(price.replace(",", "."));
  if (isNaN(price)) return "--";
  const opts = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: minFractionDigit,
    maximumFractionDigits: maxFractionDigit,
    ...options,
  };
  let formattedPrice = new Intl.NumberFormat(LOCALE, opts).format(price);
  return formattedPrice.toString();
}

function getPriceFormattedNoDecimal(price, options = {}) {
  if (isNaN(price)) return "--";
  const opts = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  };
  let formattedPrice = new Intl.NumberFormat(LOCALE, opts).format(price);
  return formattedPrice.toString();
}

function formatNonCurrency(
  arg,
  minFractionDigit = 2,
  maxFractionDigit = 2,
  taegFormat = false
) {
  let value = getPriceFormatted(
    arg,
    { style: "decimal" },
    minFractionDigit,
    maxFractionDigit
  );

  if (taegFormat && value && value !== "--")
    value = !value.includes(",") ? `${value},0` : value;
  return value;
}

function formatNonDecimalNumber(arg, options = {}) {
  if (isNaN(arg)) return "--";
  const opts = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  };
  let formattedPrice = new Intl.NumberFormat(LOCALE, opts).format(arg);
  return formattedPrice.toString();
}

function formatKilometers(arg) {
  const kms = arg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return `${kms} km`;
}

export {
  getPriceFormatted,
  formatNonCurrency,
  getPriceFormattedNoDecimal,
  formatKilometers,
  formatNonDecimalNumber,
};
