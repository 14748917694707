<template>
  <div :class="['page', customClass]" :page-name="pageName">
    <nav id="navbar">
      <slot name="navbar" />
    </nav>
    <slot name="content" />
    <footer id="footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Page",
  props: {
    pageName: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
    },
  },
};
</script>
<style lang="css" scoped src="./Page.css"></style>
