<template>
  <div class="base-auth">
    <div class="base-auth--container">
      <div class="base-auth-inputs">
        <TextLabel customClass="base-auth-input-label" label="Username" />
        <Input mode="filled" @onInput="(val) => (user = val)" />
      </div>
      <div class="base-auth-inputs">
        <TextLabel customClass="base-auth-input-label" label="Password" />
        <Input mode="filled" @onInput="(val) => (pass = val)" />
      </div>
      <div class="base-auth-btn--container">
        <Button fill customClass="btn" label="Login" @onClick="submit" />
      </div>
    </div>
  </div>
</template>

<script>
import { Input, Button, TextLabel } from "@sc/ds-ui-component-library";
export default {
  name: "BaseAuth",
  components: {
    Button,
    Input,
    TextLabel,
  },
  data() {
    return {
      user: "",
      pass: "",
    };
  },
  methods: {
    submit() {
      const user = process.env.VUE_APP_PROD_USER || "admin";
      const pass = process.env.VUE_APP_PROD_PASS || "admin";

      if (!(this.user === user) && !(this.pass === pass)) {
        this.$toast.open({
          message: "Credenciais incorretas",
          dismissible: true,
          type: "error",
        });
      }

      this.$emit("onValidate", this.user === user && this.pass === pass);
    },
  },
  mounted() {
    document.body.classList.add("no-scroll");
  },
};
</script>

<style scoped lang="scss">
.base-auth {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.base-auth--container {
  width: 30%;
}

.base-auth-inputs {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;

  :deep(.wrapper) {
    border: 2px solid var(--cv-secondary);
  }
  :deep(.text-label) {
    margin-bottom: 10px;
  }

  .base-auth-input-label {
    --text-label-color: var(--cv-secondary);
    --text-label-font-size: 16px;
    --text-label-line-height: 16px;
    --text-label-weight: 600;
  }
}

.base-auth-btn--container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  :deep(.btn) {
    margin-top: 0px;
    width: 50%;
    text-align: center;
    --button-justify-content: center;
  }
}

@media (max-width: 1024px) {
  .base-auth--container {
    width: 70%;
  }
}
</style>
